import http from '@/services/http-client';
import routes from '@/../config/env';

const { dashboardsManager } = routes;

export const getMarketMetabaseDashboard = async ({ marketId = '', query = '', timezone = 'Europe/Zagreb' }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${dashboardsManager}/dashboards/market-history/${marketId}`,
      params: { ...query, timezone },
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};
